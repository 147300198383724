import React, { FunctionComponent, cloneElement, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import BlueArticle from '@components/global/BlueArticle';
import Heading from '@components/global/Heading';
import Manual, { Step } from '@components/global/Manual';

import useTranslation from '@hooks/useTranslation';

import styles from './Steps.module.css';
import { ReactComponent as SVGStep4 } from './assets/step4.svg';

const Step1En = dynamic(() => import('./step1/lottieEn'), {
    ssr: false,
});
const Step1Pl = dynamic(() => import('./step1/lottiePl'), {
    ssr: false,
});
const Step1De = dynamic(() => import('./step1/lottieDe'), {
    ssr: false,
});
const Step1It = dynamic(() => import('./step1/lottieIt'), {
    ssr: false,
});
const Step1Es = dynamic(() => import('./step1/lottieEs'), {
    ssr: false,
});
const Step1Pt = dynamic(() => import('./step1/lottiePt'), {
    ssr: false,
});
const Step2En = dynamic(() => import('./step2/lottieEn'), {
    ssr: false,
});
const Step2Pl = dynamic(() => import('./step2/lottiePl'), {
    ssr: false,
});
const Step2De = dynamic(() => import('./step2/lottieDe'), {
    ssr: false,
});
const Step2It = dynamic(() => import('./step2/lottieIt'), {
    ssr: false,
});
const Step2Es = dynamic(() => import('./step2/lottieEs'), {
    ssr: false,
});
const Step2Pt = dynamic(() => import('./step2/lottiePt'), {
    ssr: false,
});
const Step3En = dynamic(() => import('./step3/lottieEn'), {
    ssr: false,
});
const Step3Pl = dynamic(() => import('./step3/lottiePl'), {
    ssr: false,
});
const Step3De = dynamic(() => import('./step3/lottieDe'), {
    ssr: false,
});
const Step3It = dynamic(() => import('./step3/lottieIt'), {
    ssr: false,
});
const Step3Es = dynamic(() => import('./step3/lottieEs'), {
    ssr: false,
});
const Step3Pt = dynamic(() => import('./step3/lottiePt'), {
    ssr: false,
});
const Step4En = dynamic(() => import('./step4/lottieEn'), {
    ssr: false,
});
const Step4Pl = dynamic(() => import('./step4/lottiePl'), {
    ssr: false,
});
const Step4De = dynamic(() => import('./step4/lottieDe'), {
    ssr: false,
});
const Step4It = dynamic(() => import('./step4/lottieIt'), {
    ssr: false,
});
const Step4Es = dynamic(() => import('./step4/lottieEs'), {
    ssr: false,
});
const Step4Pt = dynamic(() => import('./step4/lottiePt'), {
    ssr: false,
});

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();
    const [isRes2dppx, setIsRes2dppx] = useState(true);

    useEffect(() => {
        let mql = window.matchMedia('(min-resolution: 2dppx)');

        const handleChange = (e: MediaQueryListEvent | MediaQueryList) => {
            if (e.matches) {
                setIsRes2dppx(true);
            } else {
                setIsRes2dppx(false);
            }
        };
        handleChange(mql);
        mql.addEventListener('change', handleChange);
        return () => {
            mql.removeEventListener('change', handleChange);
        };
    }, []);

    const rendererSettings = {
        filterSize: {
            width: '200%',
            height: '200%',
            x: '-50%',
            y: '-50%',
        },
    };
    const inViewOptions = {
        threshold: 0.5,
        triggerOnce: false,
    };
    const { ref: inViewRef1, inView: inView1 } = useInView(inViewOptions);
    const { ref: inViewRef2, inView: inView2 } = useInView(inViewOptions);
    const { ref: inViewRef3, inView: inView3 } = useInView(inViewOptions);
    const { ref: inViewRef4, inView: inView4 } = useInView(inViewOptions);

    return (
        <BlueArticle>
            <article className={styles.top}>
                <Heading>
                    <Trans
                        i18nKey="stepsTitle"
                        ns="pages/features/email-marketing/ai-email-generator/index"
                    />
                </Heading>
                <p>
                    <Trans
                        i18nKey="stepsText"
                        components={{
                            strong: <strong />,
                        }}
                        ns="pages/features/email-marketing/ai-email-generator/index"
                    />
                </p>
            </article>
            <Manual>
                <Step
                    className={styles.item1}
                    title={t('stepTitle1')}
                    texts={
                        <Trans
                            i18nKey="stepText1"
                            components={{
                                strong: <strong />,
                                br: <br />,
                                p: <p />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    picture={
                        <div
                            className={styles.lottiebox}
                            ref={inViewRef1}
                        >
                            {locale === 'pl' ? (
                                <Step1Pl
                                    inView={inView1}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'de' ? (
                                <Step1De
                                    inView={inView1}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'it' ? (
                                <Step1It
                                    inView={inView1}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'es' ? (
                                <Step1Es
                                    inView={inView1}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'pt' ? (
                                <Step1Pt
                                    inView={inView1}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : (
                                <Step1En
                                    inView={inView1}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            )}
                        </div>
                    }
                />
                <Step
                    className={styles.item2}
                    title={t('stepTitle2')}
                    texts={
                        <Trans
                            i18nKey="stepText2"
                            components={{
                                strong: <strong />,
                                br: <br />,
                                p: <p />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    picture={
                        <div
                            className={styles.lottiebox}
                            ref={inViewRef2}
                        >
                            {locale === 'pl' ? (
                                <Step2Pl
                                    inView={inView2}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'de' ? (
                                <Step2De
                                    inView={inView2}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'it' ? (
                                <Step2It
                                    inView={inView2}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'es' ? (
                                <Step2Es
                                    inView={inView2}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'pt' ? (
                                <Step2Pt
                                    inView={inView2}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : (
                                <Step2En
                                    inView={inView2}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            )}
                        </div>
                    }
                />
                <Step
                    className={styles.item3}
                    title={t('stepTitle3')}
                    texts={
                        <Trans
                            i18nKey="stepText3"
                            components={{
                                strong: <strong />,
                                br: <br />,
                                p: <p />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    picture={
                        <div
                            className={styles.lottiebox}
                            ref={inViewRef3}
                        >
                            {locale === 'pl' ? (
                                <Step3Pl
                                    inView={inView3}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'de' ? (
                                <Step3De
                                    inView={inView3}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'it' ? (
                                <Step3It
                                    inView={inView3}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'es' ? (
                                <Step3Es
                                    inView={inView3}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'pt' ? (
                                <Step3Pt
                                    inView={inView3}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : (
                                <Step3En
                                    inView={inView3}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            )}
                        </div>
                    }
                />
                <Step
                    className={styles.item4}
                    icon={<SVGStep4 />}
                    title={t('stepTitle4')}
                    texts={
                        <Trans
                            i18nKey="stepText4"
                            components={{
                                strong: <strong />,
                                br: <br />,
                                p: <p />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    picture={
                        <div
                            className={styles.lottiebox}
                            ref={inViewRef4}
                        >
                            {locale === 'pl' ? (
                                <Step4Pl
                                    inView={inView4}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'de' ? (
                                <Step4De
                                    inView={inView4}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'it' ? (
                                <Step4It
                                    inView={inView4}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'es' ? (
                                <Step4Es
                                    inView={inView4}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : locale === 'pt' ? (
                                <Step4Pt
                                    inView={inView4}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            ) : (
                                <Step4En
                                    inView={inView4}
                                    isRes2dppx={isRes2dppx}
                                    rendererSettings={rendererSettings}
                                />
                            )}
                        </div>
                    }
                />
            </Manual>
        </BlueArticle>
    );
};

interface StepsProps {
    container: React.ReactElement;
}

export default function Steps({ container }: StepsProps): JSX.Element {
    return (
        <section className={styles.steps}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1220px',
            })}
        </section>
    );
}
