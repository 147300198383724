import { FunctionComponent, cloneElement } from 'react';

import Trans from '@components/Trans';
import Heading from '@components/global/Heading';
import Label from '@components/ui/Label';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';

import styles from './MainFeatures.module.css';
import { ReactComponent as SVGAiSubject } from './assets/ai-subject.svg';
import { ReactComponent as SVGIndustry } from './assets/industry.svg';
import { ReactComponent as SVGMultiligual } from './assets/multiligual.svg';
import { ReactComponent as SVGOpenAI } from './assets/open-ai.svg';

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');

    return (
        <>
            <Heading
                variant="h2"
                align="center"
            >
                <Trans
                    i18nKey={'featuresTitle'}
                    components={{
                        mark: <Mark />,
                    }}
                    ns="pages/features/email-marketing/ai-email-generator/index"
                />
            </Heading>
            <ul className={styles.list}>
                <li>
                    <SVGOpenAI
                        width={70}
                        height={70}
                    />
                    <h3>
                        <Trans
                            i18nKey={'featuresBoxTitle1'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </h3>
                    <p>
                        <Trans
                            i18nKey={'featuresBoxText1'}
                            components={{
                                strong: <strong />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </p>
                </li>
                <li>
                    <SVGAiSubject
                        width={95}
                        height={95}
                    />
                    <h3>
                        <Trans
                            i18nKey={'featuresBoxTitle2'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </h3>
                    <p>
                        <Trans
                            i18nKey={'featuresBoxText2'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </p>
                </li>
                <li>
                    <SVGIndustry
                        width={100}
                        height={100}
                    />
                    <h3>
                        <Trans
                            i18nKey={'featuresBoxTitle3'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </h3>
                    <p>
                        <Trans
                            i18nKey={'featuresBoxText3'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </p>
                </li>
                <li>
                    <SVGMultiligual
                        width={70}
                        height={70}
                    />
                    <h3>
                        <Trans
                            i18nKey={'featuresBoxTitle4'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                        <Label
                            text={t('featuresBoxTitle4Badge')}
                            className={styles.label}
                        />
                    </h3>
                    <p>
                        <Trans
                            i18nKey={'featuresBoxText4'}
                            components={{
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    </p>
                </li>
            </ul>
        </>
    );
};

interface MainFeaturesProps {
    container: React.ReactElement;
}

export default function MainFeatures({ container }: MainFeaturesProps): JSX.Element {
    return (
        <section className={styles['main-features']}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1160px',
            })}
        </section>
    );
}
