import React, { FunctionComponent, cloneElement, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Article, { Paragraph } from '@components/global/Article';
import Feature from '@components/global/Feature';
import FeatureInfo from '@components/global/FeatureInfo';
import FeaturesList from '@components/global/FeaturesList';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';
import { multisiteRouteBlogPostAiEmailGenerator, multisiteRouteBlogPostArtificialIntelligenceEmailMarketing } from '@static_components/multisiteLinks';

import styles from './OptimizedEmails.module.css';
import ABTestAVIF from './assets/ab_test@1x.avif';
import ABTestPNG from './assets/ab_test@1x.png';
import ABTestAVIF2x from './assets/ab_test@2x.avif';
import ABTestPNG2x from './assets/ab_test@2x.png';
import AIEmailAssistantAVIF from './assets/ai_email_assistant@1x.avif';
import AIEmailAssistantPNG from './assets/ai_email_assistant@1x.png';
import AIEmailAssistantAVIF2x from './assets/ai_email_assistant@2x.avif';
import AIEmailAssistantPNG2x from './assets/ai_email_assistant@2x.png';
import ABTestAVIFDE from './assets/de/a_b@1x.avif';
import ABTestPNGDE from './assets/de/a_b@1x.png';
import ABTestAVIF2xDE from './assets/de/a_b@2x.avif';
import ABTestPNG2xDE from './assets/de/a_b@2x.png';
import AIEmailAssistantAVIFDE from './assets/de/subject@1x.avif';
import AIEmailAssistantPNGDE from './assets/de/subject@1x.png';
import AIEmailAssistantAVIF2xDE from './assets/de/subject@2x.avif';
import AIEmailAssistantPNG2xDE from './assets/de/subject@2x.png';
import ABTestAVIFES from './assets/es/a_b@1x.avif';
import ABTestPNGES from './assets/es/a_b@1x.png';
import ABTestAVIF2xES from './assets/es/a_b@2x.avif';
import ABTestPNG2xES from './assets/es/a_b@2x.png';
import AIEmailAssistantAVIFES from './assets/es/subject@1x.avif';
import AIEmailAssistantPNGES from './assets/es/subject@1x.png';
import AIEmailAssistantAVIF2xES from './assets/es/subject@2x.avif';
import AIEmailAssistantPNG2xES from './assets/es/subject@2x.png';
import ABTestAVIFIT from './assets/it/b_b@1x.avif';
import ABTestPNGIT from './assets/it/b_b@1x.png';
import ABTestAVIF2xIT from './assets/it/b_b@2x.avif';
import ABTestPNG2xIT from './assets/it/b_b@2x.png';
import AIEmailAssistantAVIFIT from './assets/it/subject@1x.avif';
import AIEmailAssistantPNGIT from './assets/it/subject@1x.png';
import AIEmailAssistantAVIF2xIT from './assets/it/subject@2x.avif';
import AIEmailAssistantPNG2xIT from './assets/it/subject@2x.png';
import ABTestAVIFPL from './assets/pl/ab_test_pl@1x.avif';
import ABTestPNGPL from './assets/pl/ab_test_pl@1x.png';
import ABTestAVIF2xPL from './assets/pl/ab_test_pl@2x.avif';
import ABTestPNG2xPL from './assets/pl/ab_test_pl@2x.png';
import AIEmailAssistantAVIFPL from './assets/pl/ai_email_assistant_pl@1x.avif';
import AIEmailAssistantPNGPL from './assets/pl/ai_email_assistant_pl@1x.png';
import AIEmailAssistantAVIF2xPL from './assets/pl/ai_email_assistant_pl@2x.avif';
import AIEmailAssistantPNG2xPL from './assets/pl/ai_email_assistant_pl@2x.png';
import ABTestAVIFPT from './assets/pt/a_b@1x.avif';
import ABTestPNGPT from './assets/pt/a_b@1x.png';
import ABTestAVIF2xPT from './assets/pt/a_b@2x.avif';
import ABTestPNG2xPT from './assets/pt/a_b@2x.png';
import AIEmailAssistantAVIFPT from './assets/pt/subject@1x.avif';
import AIEmailAssistantPNGPT from './assets/pt/subject@1x.png';
import AIEmailAssistantAVIF2xPT from './assets/pt/subject@2x.avif';
import AIEmailAssistantPNG2xPT from './assets/pt/subject@2x.png';
import { ReactComponent as SVGQuote } from './assets/quote.svg';

const AnimEn = dynamic(() => import('./assets/lottieEn'), {
    ssr: false,
});
const AnimPl = dynamic(() => import('./assets/pl/lottiePl'), {
    ssr: false,
});
const AnimDe = dynamic(() => import('./assets/de/lottieDe'), {
    ssr: false,
});
const AnimIt = dynamic(() => import('./assets/it/lottieIt'), {
    ssr: false,
});
const AnimEs = dynamic(() => import('./assets/es/lottieEs'), {
    ssr: false,
});
const AnimPt = dynamic(() => import('./assets/pt/lottiePt'), {
    ssr: false,
});

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();

    const [isRes2dppx, setIsRes2dppx] = useState(true);

    useEffect(() => {
        let mql = window.matchMedia('(min-resolution: 2dppx)');

        const handleChange = (e: MediaQueryListEvent | MediaQueryList) => {
            if (e.matches) {
                setIsRes2dppx(true);
            } else {
                setIsRes2dppx(false);
            }
        };
        handleChange(mql);
        mql.addEventListener('change', handleChange);
        return () => {
            mql.removeEventListener('change', handleChange);
        };
    }, []);

    const rendererSettings = {
        filterSize: {
            width: '200%',
            height: '200%',
            x: '-50%',
            y: '-50%',
        },
    };

    const inViewOptions = {
        threshold: 0.5,
        triggerOnce: false,
    };

    const { ref: inViewRef1, inView: inView1 } = useInView(inViewOptions);

    return (
        <FeaturesList
            heading={
                <Article
                    align="center"
                    title={
                        <Trans
                            i18nKey="optimizedTitle"
                            components={{
                                mark: <Mark />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    articleContent={
                        <Paragraph>
                            <Trans
                                i18nKey="optimizedText"
                                components={{
                                    strong: <strong />,
                                    br: <br />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        </Paragraph>
                    }
                />
            }
            flipPosition={false}
            firstImagePosition="end"
        >
            <Feature
                className={styles.feature}
                article={
                    <Article
                        title={
                            <Trans
                                i18nKey="optimized1Title"
                                components={{
                                    mark: <Mark />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        variant="h3"
                        articleContent={
                            <>
                                <Trans
                                    i18nKey="optimized1Text"
                                    components={{
                                        p: <p />,
                                        strong: <strong />,
                                        mark: <Mark />,
                                        ul: <ul />,
                                        li: <li />,
                                    }}
                                    ns="pages/features/email-marketing/ai-email-generator/index"
                                />
                                <FeatureInfo
                                    text={
                                        <Trans
                                            i18nKey="optimized1Tip"
                                            components={{
                                                strong: <strong />,
                                            }}
                                            ns="pages/features/email-marketing/ai-email-generator/index"
                                        />
                                    }
                                />
                            </>
                        }
                    />
                }
                picture={
                    <picture ref={inViewRef1}>
                        {locale === 'pl' ? (
                            <AnimPl
                                inView={inView1}
                                isRes2dppx={isRes2dppx}
                                rendererSettings={rendererSettings}
                            />
                        ) : locale === 'de' ? (
                            <AnimDe
                                inView={inView1}
                                isRes2dppx={isRes2dppx}
                                rendererSettings={rendererSettings}
                            />
                        ) : locale === 'it' ? (
                            <AnimIt
                                inView={inView1}
                                isRes2dppx={isRes2dppx}
                                rendererSettings={rendererSettings}
                            />
                        ) : locale === 'es' ? (
                            <AnimEs
                                inView={inView1}
                                isRes2dppx={isRes2dppx}
                                rendererSettings={rendererSettings}
                            />
                        ) : locale === 'pt' ? (
                            <AnimPt
                                inView={inView1}
                                isRes2dppx={isRes2dppx}
                                rendererSettings={rendererSettings}
                            />
                        ) : (
                            <AnimEn
                                inView={inView1}
                                isRes2dppx={isRes2dppx}
                                rendererSettings={rendererSettings}
                            />
                        )}
                    </picture>
                }
            />
            <Feature
                className={styles.feature}
                article={
                    <Article
                        title={
                            <Trans
                                i18nKey="optimized2Title"
                                components={{
                                    mark: <Mark />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        variant="h3"
                        articleContent={
                            <>
                                <Trans
                                    i18nKey="optimized2Text"
                                    components={{
                                        p: <p />,
                                        strong: <strong />,
                                        mark: <Mark />,
                                        ul: <ul />,
                                        li: <li />,
                                    }}
                                    ns="pages/features/email-marketing/ai-email-generator/index"
                                />
                                <FeatureInfo
                                    text={
                                        <Trans
                                            i18nKey="optimized2Tip"
                                            components={{
                                                strong: <strong />,
                                            }}
                                            ns="pages/features/email-marketing/ai-email-generator/index"
                                        />
                                    }
                                    icon={
                                        <SVGQuote
                                            width={30}
                                            height={30}
                                        />
                                    }
                                    className={styles.quote}
                                />
                            </>
                        }
                    />
                }
                picture={
                    <>
                        {locale === 'pl' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${ABTestAVIFPL.src} 1x, ${ABTestAVIF2xPL.src} 2x`}
                                />
                                <source srcSet={`${ABTestPNGPL.src} 1x, ${ABTestPNG2xPL.src} 2x`} />
                                <img
                                    width={ABTestPNGPL.width}
                                    height={ABTestPNGPL.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={ABTestPNGPL.src}
                                    style={{ maxWidth: ABTestPNGPL.width }}
                                />
                            </picture>
                        ) : locale === 'de' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${ABTestAVIFDE.src} 1x, ${ABTestAVIF2xDE.src} 2x`}
                                />
                                <source srcSet={`${ABTestPNGDE.src} 1x, ${ABTestPNG2xDE.src} 2x`} />
                                <img
                                    width={ABTestPNGDE.width}
                                    height={ABTestPNGDE.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={ABTestPNGDE.src}
                                    style={{ maxWidth: ABTestPNGDE.width }}
                                />
                            </picture>
                        ) : locale === 'it' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${ABTestAVIFIT.src} 1x, ${ABTestAVIF2xIT.src} 2x`}
                                />
                                <source srcSet={`${ABTestPNGIT.src} 1x, ${ABTestPNG2xIT.src} 2x`} />
                                <img
                                    width={ABTestPNGIT.width}
                                    height={ABTestPNGIT.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={ABTestPNGIT.src}
                                    style={{ maxWidth: ABTestPNGIT.width }}
                                />
                            </picture>
                        ) : locale === 'es' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${ABTestAVIFES.src} 1x, ${ABTestAVIF2xES.src} 2x`}
                                />
                                <source srcSet={`${ABTestPNGES.src} 1x, ${ABTestPNG2xES.src} 2x`} />
                                <img
                                    width={ABTestPNGES.width}
                                    height={ABTestPNGES.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={ABTestPNGES.src}
                                    style={{ maxWidth: ABTestPNGES.width }}
                                />
                            </picture>
                        ) : locale === 'pt' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${ABTestAVIFPT.src} 1x, ${ABTestAVIF2xPT.src} 2x`}
                                />
                                <source srcSet={`${ABTestPNGPT.src} 1x, ${ABTestPNG2xPT.src} 2x`} />
                                <img
                                    width={ABTestPNGPT.width}
                                    height={ABTestPNGPT.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={ABTestPNGPT.src}
                                    style={{ maxWidth: ABTestPNGPT.width }}
                                />
                            </picture>
                        ) : (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${ABTestAVIF.src} 1x, ${ABTestAVIF2x.src} 2x`}
                                />
                                <source srcSet={`${ABTestPNG.src} 1x, ${ABTestPNG2x.src} 2x`} />
                                <img
                                    width={ABTestPNG.width}
                                    height={ABTestPNG.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={ABTestPNG.src}
                                    style={{ maxWidth: ABTestPNG.width }}
                                />
                            </picture>
                        )}
                    </>
                }
            />
            <Feature
                className={styles.feature}
                article={
                    <Article
                        title={
                            <Trans
                                i18nKey="optimized3Title"
                                components={{
                                    mark: <Mark />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        variant="h3"
                        articleContent={
                            <>
                                <Trans
                                    i18nKey="optimized3Text"
                                    components={{
                                        p: <p />,
                                        strong: <strong />,
                                        mark: <Mark />,
                                        ul: <ul />,
                                        li: <li />,
                                    }}
                                    ns="pages/features/email-marketing/ai-email-generator/index"
                                />
                                <FeatureInfo
                                    text={
                                        <Trans
                                            i18nKey="optimized3Tip"
                                            components={{
                                                strong: <strong />,
                                                URL1: locale === 'it' ? <a href={multisiteRouteBlogPostArtificialIntelligenceEmailMarketing(locale)} /> : <a href={multisiteRouteBlogPostAiEmailGenerator(locale)} />,
                                            }}
                                            ns="pages/features/email-marketing/ai-email-generator/index"
                                        />
                                    }
                                />
                            </>
                        }
                    />
                }
                picture={
                    <>
                        {locale === 'pl' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${AIEmailAssistantAVIFPL.src} 1x, ${AIEmailAssistantAVIF2xPL.src} 2x`}
                                />
                                <source srcSet={`${AIEmailAssistantPNGPL.src} 1x, ${AIEmailAssistantPNG2xPL.src} 2x`} />
                                <img
                                    width={AIEmailAssistantPNGPL.width}
                                    height={AIEmailAssistantPNGPL.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={AIEmailAssistantPNGPL.src}
                                    style={{ maxWidth: AIEmailAssistantPNGPL.width }}
                                />
                            </picture>
                        ) : locale === 'de' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${AIEmailAssistantAVIFDE.src} 1x, ${AIEmailAssistantAVIF2xDE.src} 2x`}
                                />
                                <source srcSet={`${AIEmailAssistantPNGDE.src} 1x, ${AIEmailAssistantPNG2xDE.src} 2x`} />
                                <img
                                    width={AIEmailAssistantPNGDE.width}
                                    height={AIEmailAssistantPNGDE.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={AIEmailAssistantPNGDE.src}
                                    style={{ maxWidth: AIEmailAssistantPNGDE.width }}
                                />
                            </picture>
                        ) : locale === 'it' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${AIEmailAssistantAVIFIT.src} 1x, ${AIEmailAssistantAVIF2xIT.src} 2x`}
                                />
                                <source srcSet={`${AIEmailAssistantPNGIT.src} 1x, ${AIEmailAssistantPNG2xIT.src} 2x`} />
                                <img
                                    width={AIEmailAssistantPNGIT.width}
                                    height={AIEmailAssistantPNGIT.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={AIEmailAssistantPNGIT.src}
                                    style={{ maxWidth: AIEmailAssistantPNGIT.width }}
                                />
                            </picture>
                        ) : locale === 'es' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${AIEmailAssistantAVIFES.src} 1x, ${AIEmailAssistantAVIF2xES.src} 2x`}
                                />
                                <source srcSet={`${AIEmailAssistantPNGES.src} 1x, ${AIEmailAssistantPNG2xES.src} 2x`} />
                                <img
                                    width={AIEmailAssistantPNGES.width}
                                    height={AIEmailAssistantPNGES.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={AIEmailAssistantPNGES.src}
                                    style={{ maxWidth: AIEmailAssistantPNGES.width }}
                                />
                            </picture>
                        ) : locale === 'pt' ? (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${AIEmailAssistantAVIFPT.src} 1x, ${AIEmailAssistantAVIF2xPT.src} 2x`}
                                />
                                <source srcSet={`${AIEmailAssistantPNGPT.src} 1x, ${AIEmailAssistantPNG2xPT.src} 2x`} />
                                <img
                                    width={AIEmailAssistantPNGPT.width}
                                    height={AIEmailAssistantPNGPT.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={AIEmailAssistantPNGPT.src}
                                    style={{ maxWidth: AIEmailAssistantPNGPT.width }}
                                />
                            </picture>
                        ) : (
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${AIEmailAssistantAVIF.src} 1x, ${AIEmailAssistantAVIF2x.src} 2x`}
                                />
                                <source srcSet={`${AIEmailAssistantPNG.src} 1x, ${AIEmailAssistantPNG2x.src} 2x`} />
                                <img
                                    width={AIEmailAssistantPNG.width}
                                    height={AIEmailAssistantPNG.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={AIEmailAssistantPNG.src}
                                    style={{ maxWidth: AIEmailAssistantPNG.width }}
                                />
                            </picture>
                        )}
                    </>
                }
            />
        </FeaturesList>
    );
};

interface OptimizedEmailsProps {
    container: React.ReactElement;
}

export default function OptimizedEmails({ container }: OptimizedEmailsProps) {
    return (
        <section className={styles['optimized-emails']}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1140px',
            })}
        </section>
    );
}
