import * as React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { canonical } from '@helpers/alternates';

import useTranslation from '@hooks/useTranslation';

import imgpng1 from './assets/STEP1.png';
import imgpng2 from './assets/STEP2.png';
import imgpng3 from './assets/STEP3.png';
import imgpng4 from './assets/step4.png';

interface JsonLdProps {
    canonicalOrigin?: string;
}

export default function JsonLd({ canonicalOrigin }: JsonLdProps): JSX.Element {
    const { t } = useTranslation();
    const { locale, pathname } = useRouter();
    const canonicalUrl = canonicalOrigin ? canonicalOrigin : canonical(pathname, locale);

    const website = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'WebPage',
                '@id': canonicalUrl + '#webpage',
                url: canonicalUrl,
                name: t('pages/features/email-marketing/ai-email-generator/index:mainTitle').replace(/<[^>]+>/gi, ''),
                description: t('pages/features/email-marketing/ai-email-generator/index:seo.description').replace(/<[^>]+>/gi, ''),
                potentialAction: [
                    {
                        '@type': 'ReadAction',
                        target: [canonicalUrl],
                    },
                ],
            },
            {
                '@type': 'FAQPage',
                mainEntity: [
                    {
                        '@type': 'Question',
                        name: t('pages/features/email-marketing/ai-email-generator/index:faqQ1'),
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: t('pages/features/email-marketing/ai-email-generator/index:faqA1'),
                        },
                    },
                    {
                        '@type': 'Question',
                        name: t('pages/features/email-marketing/ai-email-generator/index:faqQ2'),
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: t('pages/features/email-marketing/ai-email-generator/index:faqA2'),
                        },
                    },
                    {
                        '@type': 'Question',
                        name: t('pages/features/email-marketing/ai-email-generator/index:faqQ3'),
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: t('pages/features/email-marketing/ai-email-generator/index:faqA3'),
                        },
                    },
                    {
                        '@type': 'Question',
                        name: t('pages/features/email-marketing/ai-email-generator/index:faqQ4'),
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: t('pages/features/email-marketing/ai-email-generator/index:faqA4'),
                        },
                    },
                ],
            },
            {
                '@type': 'HowTo',
                name: t('pages/features/email-marketing/ai-email-generator/index:stepsTitle'),
                step: [
                    {
                        '@type': 'HowToStep',
                        text: t('pages/features/email-marketing/ai-email-generator/index:stepText1'),
                        image: imgpng1.src,
                        name: t('pages/features/email-marketing/ai-email-generator/index:stepTitle1'),
                    },
                    {
                        '@type': 'HowToStep',
                        text: t('pages/features/email-marketing/ai-email-generator/index:stepText2'),
                        image: imgpng2.src,
                        name: t('pages/features/email-marketing/ai-email-generator/index:stepTitle2'),
                    },
                    {
                        '@type': 'HowToStep',
                        text: t('pages/features/email-marketing/ai-email-generator/index:stepText3'),
                        image: imgpng3.src,
                        name: t('pages/features/email-marketing/ai-email-generator/index:stepTitle3'),
                    },
                    {
                        '@type': 'HowToStep',
                        text: t('pages/features/email-marketing/ai-email-generator/index:stepText4'),
                        image: imgpng4.src,
                        name: t('pages/features/email-marketing/ai-email-generator/index:stepTitle4'),
                    },
                ],
            },
        ],
    };

    return (
        <Head>
            <script
                id="website"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(website),
                }}
            />
        </Head>
    );
}
