import React, { FunctionComponent, cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Article, { Paragraph } from '@components/global/Article';
import FeatureItem from '@components/global/FeatureItem';
import Split, { Column1, Column2 } from '@components/global/Split';
import { Button } from '@components/ui/Button';
import Mark from '@components/ui/Mark';
import { More } from '@components/ui/More';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteMax, laravelRouteMaxBookADemo } from '@static_components/laravelLinks';

import styles from './Black.module.css';
import { ReactComponent as SVGIcon1 } from './assets/ico-1.svg';
import { ReactComponent as SVGIcon2 } from './assets/ico-2.svg';
import { ReactComponent as SVGIcon3 } from './assets/ico-3.svg';

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();

    return (
        <Split reverse={true}>
            <Column1>
                <Article
                    title={
                        <Trans
                            i18nKey="blackTitle"
                            components={{
                                mark: <Mark color="orange" />,
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    articleContent={
                        <>
                            <Paragraph>
                                <Trans
                                    i18nKey="blackText"
                                    components={{
                                        strong: <strong />,
                                        br: <br />,
                                    }}
                                    ns="pages/features/email-marketing/ai-email-generator/index"
                                />
                            </Paragraph>
                            <Button
                                href={laravelRouteMaxBookADemo(locale)}
                                label={t('blackBtn')}
                                color="orange"
                            />
                        </>
                    }
                    link={
                        <More
                            href={laravelRouteMax(locale)}
                            color="blue"
                            label={t('blackLink')}
                            size="big"
                        />
                    }
                />
            </Column1>
            <Column2 type="other">
                <ul className={styles.list}>
                    <FeatureItem
                        icon={
                            <SVGIcon1
                                width={65}
                                height={65}
                            />
                        }
                        text={
                            <Trans
                                i18nKey="blackItem1"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        textColor="white"
                    />
                    <FeatureItem
                        icon={
                            <SVGIcon2
                                width={52}
                                height={52}
                            />
                        }
                        text={
                            <Trans
                                i18nKey="blackItem2"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        textColor="white"
                    />
                    <FeatureItem
                        icon={
                            <SVGIcon3
                                width={52}
                                height={52}
                            />
                        }
                        text={
                            <Trans
                                i18nKey="blackItem3"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        textColor="white"
                    />
                </ul>
            </Column2>
        </Split>
    );
};

interface BlackProps {
    container: React.ReactElement;
}

export default function Black({ container }: BlackProps): JSX.Element {
    return (
        <section className={styles.black}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1000px',
            })}
        </section>
    );
}
