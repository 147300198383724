import { FunctionComponent, cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Carousel from '@components/global/Carousel';
import carouselStyles from '@components/global/Carousel/CarouselResources.module.css';
import ResourceCard from '@components/global/ResourceCard';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';
import {
    multisiteRouteBlogPostEmailMarketingDlaPoczatkujacych,
    multisiteRouteBlogPostWasIstEMailMarketing,
    multisiteRouteHelpCategoryEMailMarketing,
    multisiteRouteHelpPostCzymJestGeneratorTematowAiIJakGoUzywac,
} from '@static_components/multisiteLinks';

import styles from './Resources.module.css';
import slide1AVIF from './assets/resources-01@1x.avif';
import slide1PNG from './assets/resources-01@1x.png';
import slide1AVIF2x from './assets/resources-01@2x.avif';
import slide1PNG2x from './assets/resources-01@2x.png';
import slide2AVIF from './assets/resources-02@1x.avif';
import slide2PNG from './assets/resources-02@1x.png';
import slide2AVIF2x from './assets/resources-02@2x.avif';
import slide2PNG2x from './assets/resources-02@2x.png';
import slide3AVIF from './assets/resources-03@1x.avif';
import slide3PNG from './assets/resources-03@1x.png';
import slide3AVIF2x from './assets/resources-03@2x.avif';
import slide3PNG2x from './assets/resources-03@2x.png';

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();
    return (
        <>
            <h2>
                <Trans
                    i18nKey="resourcesTitle"
                    components={{
                        mark: <Mark />,
                    }}
                    ns="pages/features/email-marketing/ai-email-generator/index"
                />
            </h2>
            <Carousel customStyles={carouselStyles}>
                <ResourceCard
                    title={t('resourcesTitle1')}
                    text={t('resourcesText1')}
                    more={t('resourcesLink1')}
                    linkAlignedToBottom={false}
                    href={locale === 'pl' ? multisiteRouteBlogPostEmailMarketingDlaPoczatkujacych(locale) : multisiteRouteBlogPostWasIstEMailMarketing(locale)}
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${slide1AVIF.src} 1x, ${slide1AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${slide1PNG.src} 1x, ${slide1PNG2x.src} 2x`} />
                            <img
                                width={slide1PNG.width}
                                height={slide1PNG.height}
                                loading="lazy"
                                decoding="async"
                                src={slide1PNG.src}
                                alt={t('resourcesTitle1')}
                            />
                        </picture>
                    }
                />
                <ResourceCard
                    title={t('resourcesTitle2')}
                    text={t('resourcesText2')}
                    more={t('resourcesLink2')}
                    linkAlignedToBottom={false}
                    href={multisiteRouteHelpPostCzymJestGeneratorTematowAiIJakGoUzywac(locale)}
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${slide2AVIF.src} 1x, ${slide2AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${slide2PNG.src} 1x, ${slide2PNG2x.src} 2x`} />
                            <img
                                width={slide2PNG.width}
                                height={slide2PNG.height}
                                loading="lazy"
                                decoding="async"
                                src={slide2PNG.src}
                                alt={t('resourcesTitle2')}
                            />
                        </picture>
                    }
                />
                <ResourceCard
                    title={t('resourcesTitle3')}
                    text={t('resourcesText3')}
                    more={t('resourcesLink3')}
                    linkAlignedToBottom={false}
                    href={multisiteRouteHelpCategoryEMailMarketing(locale)}
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${slide3AVIF.src} 1x, ${slide3AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${slide3PNG.src} 1x, ${slide3PNG2x.src} 2x`} />
                            <img
                                width={slide3PNG.width}
                                height={slide3PNG.height}
                                loading="lazy"
                                decoding="async"
                                src={slide3PNG.src}
                                alt={t('resourcesTitle3')}
                            />
                        </picture>
                    }
                />
            </Carousel>
        </>
    );
};

interface ResourcesProps {
    container: React.ReactElement;
}

export default function Resources({ container }: ResourcesProps): JSX.Element {
    return (
        <section className={styles.resources}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1140px',
            })}
        </section>
    );
}
