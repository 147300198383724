import { cloneElement } from 'react';

import Trans from '@components/Trans';
import Mark from '@components/ui/Mark';

import styles from './Faq.module.css';

const Content = ({ children }: React.PropsWithChildren) => {
    return (
        <>
            <h2>
                <Trans
                    i18nKey="faqTitle"
                    components={{
                        mark: <Mark />,
                        br: <br />,
                    }}
                    ns="pages/features/email-marketing/ai-email-generator/index"
                />
            </h2>
            <div className={styles.questions}>{children}</div>
        </>
    );
};

interface FaqProps {
    container: React.ReactElement;
}

export function Faq({ container, children }: React.PropsWithChildren<FaqProps>) {
    return (
        <section className={styles.faq}>
            {cloneElement(container, {
                children: <Content>{children}</Content>,
                maxWidth: '1060px',
            })}
        </section>
    );
}
