import React, { cloneElement } from 'react';

import FreeTrialTestimonial from '@components/global/FreeTrialTestimonial';

import useTranslation from '@hooks/useTranslation';

import styles from './StartFree.module.css';
import imgAVIF from './assets/robert_joswick@1x.avif';
import imgPNG from './assets/robert_joswick@1x.png';
import imgAVIF2x from './assets/robert_joswick@2x.avif';
import imgPNG2x from './assets/robert_joswick@2x.png';

interface InnerContentProps {
    version?: string;
    pageinfo?: string;
}

const Content = ({ version, pageinfo }: InnerContentProps) => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');

    return (
        <FreeTrialTestimonial
            className={styles.testimonial}
            picture={
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${imgAVIF.src} 1x, ${imgAVIF2x.src} 2x`}
                    />
                    <source srcSet={`${imgPNG.src} 1x, ${imgPNG2x.src} 2x`} />
                    <img
                        width={imgPNG.width}
                        height={imgPNG.height}
                        decoding="async"
                        loading="lazy"
                        alt={t('startFreeTitle')}
                        src={imgPNG.src}
                    />
                </picture>
            }
            quoteSize="medium"
            title={t('startFreeTitle')}
            author={t('startFreeAuthor')}
            quote={t('startFreeQuote')}
            sftVersion={version}
            pageInfo={pageinfo}
            button={t('startFreeBtn')}
        />
    );
};

interface StartFreeProps extends InnerContentProps {
    container: React.ReactElement;
}

export default function StartFree({ container, version, pageinfo }: StartFreeProps): JSX.Element {
    return (
        <section className={styles.startfree}>
            {cloneElement(container, {
                children: (
                    <Content
                        version={version}
                        pageinfo={pageinfo}
                    />
                ),
                maxWidth: '1140px',
            })}
        </section>
    );
}
